import * as React from 'react'
import { Helmet } from 'react-helmet'

import CustomCookieConsent from '../components/CustomCookieConsent'
import { Link, navigate } from 'gatsby'
import logo from '../images/kunyit-logo.svg'
import '../styles/main.sass'
import Footer from '../components/Footer'

const App = () => {
	const goBack = () => {
		navigate('/')
	}

	return (
		<main>
			<CustomCookieConsent />
			<Helmet>
				<title>Kunyit - Rouwpsycholoog Karin Seydel</title>
			</Helmet>
			<div className={'block block-landing block-404'}>
				<div className={'block-content'}>
					<div className={'header'}>
						<div className={'logo-container'}>
							<img src={logo} alt={'Kunyit'} />
							<h2>Karin Seydel</h2>
							<h3>Rouwpsycholoog</h3>
						</div>
						<div className={'menu-container'}>
							<ul role={'navigation'} className={'menu'}>
								<li>
									<button onClick={goBack} className={'menu-button'}>
										Terug
									</button>
								</li>
							</ul>
						</div>
					</div>
					<div className={'text-container white-container'}>
						<h1>Deze pagina bestaat niet</h1>
						<p>
							Was je ergens naar op zoek? Klik <Link to={'/'}>hier</Link> om
							terug te gaan naar de homepagina.
						</p>
						<p>
							Heb je onbeantwoorde vragen? Neem contact met mij op. Onderaan de
							homepagina kun je mij direct een email sturen, of bel mij op{' '}
							<a href={'tel:31621588511'}>06 21 58 85 11</a>.
						</p>
					</div>
				</div>
			</div>
			<Footer outsideStore={true} />
		</main>
	)
}

export default App
